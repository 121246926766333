@use '@angular/material' as mat;

:root {
  --color-bg-sidebar: #0e0a01;
}

@include mat.elevation-classes();
@include mat.app-background();

$custom-primary: mat.m2-define-palette(mat.$m2-red-palette, 500);
$custom-accent: mat.m2-define-palette(mat.$m2-yellow-palette, A200);
$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

@include mat.datepicker-theme($custom-theme);

/* 
  General printing styles
  The DOM that is needed to be included on print can be marked with "print:visible"
*/
@media print {
  body > * {
    display: none !important;
  }

  .cdk-global-scrollblock {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
  }

  .cdk-overlay-container {
    display: block !important;
    position: relative !important;
  }
}

.cdk-dialog-container {
  outline: none !important;
}
